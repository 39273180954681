import React from 'react';
import './App.css';

function OnAirWidget() {
    return (
        <div>
            <h1>On Air Coming Soon</h1>
        </div>
    )
}

export default OnAirWidget;