import React from 'react';
import './App.css';

function PodcastsWidget() {
    return (
        <div>
            <h1>Podcasts Coming Soon</h1>
        </div>
    )
}

export default PodcastsWidget;