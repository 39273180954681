import React from 'react';
import './App.css';

function NewsWidget() {
    return (
        <div>
            <h1>News Coming Soon</h1>
        </div>
    )
}

export default NewsWidget;